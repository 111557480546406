import React, { useRef, useState } from "react";
import { AgePrompt } from "./components/age-prompt";
import { SocialIcon } from "react-social-icons";

function App() {
  const [mode, setMode] = useState<"prompt" | "waitlist" | "underage">(
    "prompt"
  );

  const button = useRef<HTMLButtonElement | null>(null);

  return (
    <main className="max-w-[512px] mx-auto">
      <img src={require('./images/background.png')} />
      {mode === "waitlist" && (
        <div className="p-4 flex flex-col">
          <h1 className="font-luckiestguy text-white text-center text-6xl drop-shadow-xl">
            SockSmash!
          </h1>
          <h2 className="text-white text-center text-xs">
            Your exclusive sock fetish community
          </h2>
          <h3 className="text-white text-center mt-8 text-3xl">
            Sign up for the waitlist
          </h3>
          <p className="mt-4 text-white">
            socksmash! is an upcoming social app dedicated to gay men with a taste
            for socks and feet. Sign up for the waitlist now to receive periodic email
            newsletters concerning the ongoing development of this project.
            Your email address will not be used for any further purposes and you
            may unsubscribe at any time.
          </p>
          <form
            className="flex flex-col"
            action="https://formspree.io/f/mqazvwzq"
            method="POST"
          >
            <label className="mt-8 text-white" htmlFor="email">
              Email
            </label>
            <input
              className="bg-white border-4 p-4 border-primary1 focus:border-accent1 focus:outline-none"
              type="email"
              name="email"
              id="email"
            />
            <button
              className="bg-accent1 font-bold p-4 text-white rounded-md mt-8"
              type="submit"
              ref={button}
            >
              Join waitlist
            </button>
          </form>
          <h3 className="text-white text-center mt-8 text-3xl">
            Follow &amp; share
          </h3>
          <div className="flex flex-row justify-center">
            <div className="m-4">
              <SocialIcon url="https://www.instagram.com/socksmashapp/" />
            </div>
            <div className="m-4">
              <SocialIcon url="https://x.com/socksmashapp" />
            </div>
            <div className="m-4">
              <SocialIcon onClick={async () => {
                await navigator.share({
                  url: "https://join.socksmash.com"
                });
              }} style={{cursor: "pointer"}} />
            </div>
          </div>
        </div>
      )}

      {mode === "prompt" && (
        <AgePrompt
          onSuccess={() => {
            setMode("waitlist");
            window.setTimeout(() => {
              button.current?.scrollIntoView({ behavior: "smooth" })
            }, 1);
          }}
          onFailure={() => setMode("underage")}
        />
      )}

      {mode === "underage" && (
        <div className="p-4 flex flex-col">
          <h1 className="font-luckiestguy text-white text-center text-6xl drop-shadow-xl">
            SockSmash!
          </h1>
          <h2 className="text-white text-center text-xs">
            Your exclusive sock fetish community
          </h2>
          <p className="mt-4 text-white text-center">
            You are not old enough to enter this website.
          </p>
        </div>
      )}
    </main>
  );
}

export default App;
