import React, { useState } from "react";
import {
  enumerateDays,
  enumerateMonths,
  enumerateYears,
  calculateAge,
} from "../utilities/age-prompt";

export function AgePrompt({
  onSuccess,
  onFailure,
}: {
  onSuccess: () => void;
  onFailure: () => void;
}) {
  const years = enumerateYears();
  const months = enumerateMonths();

  const [selectedYear, setSelectedYear] = useState<number>(-1);
  const [selectedMonth, setSelectedMonth] = useState<number>(-1);
  const [selectedDay, setSelectedDay] = useState<number>(-1);

  return (
    <div className="p-4 flex flex-col">
      <h1 className="font-luckiestguy text-white text-center text-6xl drop-shadow-xl">
        SockSmash!
      </h1>
      <h2 className="text-white text-center text-xs">
        Your exclusive sock fetish community
      </h2>
      <p className="mt-4 text-white">
        Please enter your date of birth to continue.
      </p>
      <div className="flex flex-row justify-between">
        <select
          style={{WebkitAppearance: 'none'}}
          className="p-2 w-full border-4 border-primary1 focus:border-accent1 focus:outline-none"
          onChange={(event) => {
            setSelectedYear(Number(event.target.value));
            setSelectedMonth(-1);
            setSelectedDay(-1);
          }}
          value={selectedYear}
        >
          <option value={-1}>Year</option>
          {years.map((year) => (
            <option value={year.value}>{year.label}</option>
          ))}
        </select>
        <select
          style={{WebkitAppearance: 'none'}}
          className="p-2 w-full border-4 border-primary1 focus:border-accent1 focus:outline-none"
          onChange={(event) => {
            setSelectedMonth(Number(event.target.value));
            setSelectedDay(-1);
          }}
          value={selectedMonth}
        >
          <option value={-1}>Month</option>
          {months.map((month) => (
            <option value={month.value}>{month.label}</option>
          ))}
        </select>
        <select
          style={{WebkitAppearance: 'none'}}
          className="p-2 w-full border-4 border-primary1 focus:border-accent1 focus:outline-none"
          onChange={(event) => {
            setSelectedDay(Number(event.target.value));
          }}
          value={selectedDay}
        >
          <option value={-1}>Day</option>
          {selectedYear &&
            selectedMonth &&
            enumerateDays(selectedYear, selectedMonth).map((day) => (
              <option value={day.value}>{day.label}</option>
            ))}
        </select>
      </div>
      {selectedYear !== -1 && selectedMonth !== -1 && selectedDay !== -1 && (
        <button
          className="bg-accent1 font-bold p-4 text-white rounded-md mt-8"
          type="button"
          onClick={() => {
            var birthDate = new Date(selectedYear, selectedMonth, selectedDay);
            if (calculateAge(birthDate) >= 18) {
              onSuccess();
            } else {
              onFailure();
            }
          }}
        >
          Confirm age
        </button>
      )}

      {(selectedYear === -1 || selectedMonth === -1 || selectedDay === -1) && (
        <button
          className="bg-primary1 font-bold p-4 text-white rounded-md mt-8"
          type="button"
          onClick={() => {
            alert('Please enter your date of birth for age verification.');
          }}
        >
          Confirm age
        </button>
      )}
    </div>
  );
}
