export function enumerateYears(): Option[] {
  let years: Option[] = [];
  for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 100; i--) {
    years.push({ label: i.toString(), value: i });
  }
  return years;
}

interface Option {
  label: string;
  value: number | undefined;
}

export function enumerateMonths(): Option[] {
  let months: Option[] = [];
  let date = new Date();
  for (let i = 0; i < 12; i++) {
    date.setMonth(i);
    months.push({
      label: date.toLocaleDateString("en", { month: "long" }),
      value: i,
    });
  }
  return months;
}

export function enumerateDays(year: number, month: number): Option[] {
  let days: Option[] = [];
  for(let i = 1; i < getDaysInMonth(year, month) + 1; i++) {
    days.push({
        label: i.toString(),
        value: i
    });
  }
  return days;
}

export function getDaysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate();
}

export function calculateAge(birthDate: Date) {
    var now = new Date();

    var age = now.getFullYear() - birthDate.getFullYear();

    var monthDiff = now.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}